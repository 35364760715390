

// Mestre Empire - Icons

$mstr-mestre: "\e903";
$mstr-signature: "\e904";
$mstr-mestre-within: "\e902";
$mstr-crown: "\e900";
$mstr-established: "\e901";
$mstr-pillar-power: "\e911";
$mstr-pillar-vision: "\e912";
$mstr-pillar-wisdom: "\e913";
$mstr-sp-o: "\e915";
$mstr-sp: "\e916";

.sprk-icon {
  display: inline-block;
  width: 120px;
  height: 120px;
  font-size: 28px;
  line-height: 120px;
  text-align: center;
  color: color(primary);
  background: color(tertiaryghost);
  border-radius: 200%;
  @include textShadow(16);
}

@font-face {
  font-family: 'mestreempire';
  src:  url('../../assets/fonts/mestreempire/mestreempire.eot');
  src:  url('../../assets/fonts/mestreempire/mestreempire.eot') format('embedded-opentype'),
    url('../../assets/fonts/mestreempire/mestreempire.ttf') format('truetype'),
    url('../../assets/fonts/mestreempire/mestreempire.woff') format('woff'),
    url('../../assets/fonts/mestreempire/mestreempire.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}


.mstr-icons {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'mestreempire' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  font-size: 24px;
  
  /* Enable Ligatures ================ */
  letter-spacing: 0;
  -webkit-font-feature-settings: "liga";
  -moz-font-feature-settings: "liga=1";
  -moz-font-feature-settings: "liga";
  -ms-font-feature-settings: "liga" 1;
  font-feature-settings: "liga";
  -webkit-font-variant-ligatures: discretionary-ligatures;
  font-variant-ligatures: discretionary-ligatures;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.mstr-mestre {
  &:before {
    content: $mstr-mestre;
  }
}
.mstr-signature {
  &:before {
    content: $mstr-signature;
  }
}
.mstr-mestre-within {
  &:before {
    content: $mstr-mestre-within;
  }
}
.mstr-crown {
  &:before {
    content: $mstr-crown;
  }
}
.mstr-established {
  &:before {
    content: $mstr-established;
  }
}
.mstr-pillar-power {
  &:before {
    content: $mstr-pillar-power;
  }
}
.mstr-pillar-vision {
  &:before {
    content: $mstr-pillar-vision;
  }
}
.mstr-pillar-wisdom {
  &:before {
    content: $mstr-pillar-wisdom;
  }
}
.mstr-sp-o {
  &:before {
    content: $mstr-sp-o;
  }
}
.mstr-sp {
  &:before {
    content: $mstr-sp;
  }
}




