* {
	padding: 0;
	margin: 0;
	list-style: none;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}


#sprk-theme {
	color: color(secondarydarker);


	h1, h2, h3, h4, h5 {
		position: relative;
		display: block;
		margin: 40px auto 40px auto;
		padding: 0;
		font-family: 'Roboto Condensed', sans-serif;
		font-weight: bold;
		line-height: 2em;
		color: color(secondarydarker);
		transition: 0.3s ease;
		max-width: $content_width;
		text-align: center;

		strong {
			font-weight: bold;
			color: color(secondarydark);
		}

		span {
			display: block;
			opacity: 0.75;
			font-family: 'Raleway';
			font-size: 0.5em;
			text-transform: uppercase;
		}

		&.split {
			color: color(secondary);

			strong {
				color: color(secondarydarker);
			}
		}

		i {
			color: color(primary);
		}

	}

	h1 {
		font-size: 2.2em;
	}

	h2 {
		font-size: 2em;

		&:before,
		&:after {
			content: '';
			position: absolute;
			bottom: -10px;
			left: 50%;
			display: block;
			width: 40%;
			height: 1px;
			margin: auto;
			border-bottom: 1px solid color(primary);
			transform: translate(-50%, 0);
		}

		&:after {
			bottom: -18px;
			width: 30%;
			border-bottom: 1px solid color(tertiary);
			opacity: 0.5;
		}
	}

	h3 {
		font-size: 1.5em;
		line-height: 1.2em;
		color: color(secondarydark);
	}

	h4, h5 {
		font-size: 1.2em;
		line-height: 1.2em;
	}

	sup {
		// font-size: 0.75em;
		font-weight: normal;
		top: -0.2em;
		// vertical-align: top;
		// margin: 0 2px 0 0;
	}



	p {
		max-width: $content_width;
		margin: 2em 20px;
		// font-size: 1.3em;
		line-height: 1.7;
		// color: color(boulder);

		@include susy-breakpoint($bp-md, $susy) {
			margin: 2em auto;
		}

		&.intro-text {
			font-size: 1.5em;
		}

		&.sprk-page--print {
			font-size: 1em;
			line-height: 1.5em;
			text-align: center;
			opacity: 0.5;
		}

		&.feature-text {
			font-family: 'Playfair Display';
			font-size: 60px;
			line-height: 1;
			color: color(primary);
			margin: 0 auto;
		}

		&.disclaimer {
			display: block;
		    padding: 5px 10px;
		    margin: 2em auto;
			font-size: 14px;
		    line-height: 1.5;
		    background: color(secondaryghost);
		    border-radius: 3px;
		}

		&.small-text {
			display: block;
			font-size: 1em;
		    line-height: 1.5;
		    margin: 0.8em auto;
		}

		&.hashtag-text {
			font-size: 1.5em;
			font-weight: bold;
			font-family: "Roboto Condensed";
			text-transform: uppercase;
			opacity: 0.25;
		}
	}

	label {
		display: block;
		font-size: 12px;
		text-transform: uppercase;
		margin: 0 auto;
		// font-weight: bold;
		// color: color(charcoal);
	}

	// Links

	a {
		color: color(tertiary);
		text-decoration: none;

		&:active,
		&.active {
			font-weight: bold;
		}

		&:hover {
			cursor: pointer;
			color: color(primarydark);
		}

		&.post-edit-link {
			display: inline-block;
			line-height: 1.8;
			font-size: 14px;
			padding: 0 15px;
			margin: 0 5px 0 0;
			background: color(charcoal);
			color: color(white);
			border-radius: 3px;

			&:hover {
				background: color(nero);
			}
		}

		strong, b {
			color: inherit;
		}
	}

	strong, b {
		color: color(secondarydarker);
	}

	em {
		font-family: 'Playfair Display';
		font-style: italic;
		color: color(charcoal);
	}


	blockquote {
		position: relative;
		display: inline-block;
		padding: 20px 50px;
		margin: 1em 20px;
		font-family: 'Playfair Display';
		font-style: italic;
		font-size: 1.3em;
		text-align: center;
		max-width: $content_width;
		background: color(tertiaryghost);
		border-radius: 5px;

		@include susy-breakpoint($bp-md, $susy) {
			padding: 20px 60px;
		}

		p {
			margin: 0;
			padding: 0;
			font-size: 0.85em;

			@include susy-breakpoint($bp-md, $susy) {
				font-size: 1em;
				padding: 20px;
			}
		}

		&::before,
		&::after {
			position: absolute;
			display: block;
			width: 50px;
			height: 50px;
			text-align: center;
			font-family: 'Font Awesome\ 5 Free', 'FontAwesome';
			font-size: 30px;
			color: color(secondary);
			opacity: 0.15;
		}

		&::before {
			content: '\f10d';
			top: 40px;
			left: 5px;

			@include susy-breakpoint($bp-md, $susy) {
				left: 20px;
			}
		}

		&::after {
			content: '\f10e';
			position: absolute;
			bottom: 40px;
			right: 5px;

			@include susy-breakpoint($bp-md, $susy) {
				riight: 20px;
			}

		}

		span {
			display: block;
			margin-top: 20px;
			padding: 5px;
		    font-family: 'Roboto Condensed';
		    font-size: 0.6em;
		    text-transform: uppercase;
		    color: color(primary);
		    background: color(white);
			border-radius: 5px;
		}

	}

	hr {
		border: none;
		border-bottom: 1px solid color(primarylight);
		margin: 60px 0;
	}


	ul.list {
		position: relative;
		margin: 0 auto;
		padding: 20px;
		max-width: $content_width;
		background: color(secondaryghost);
		border-radius: 5px;

		li {
			position: relative;
			margin: 15px 0 15px 35px;
			padding: 10px;
			line-height: 1.5em;
			text-align: left;
			list-style-type: none;
			border-bottom: 1px solid color(primarylight);

			&::before {
				content: '';
				@include center(false, true);
				left: -25px;
				width: 8px;
				height: 8px;
				background: color(secondary);
				border-radius: 200%;
			}

			&:last-child {
				border-bottom: none;
			}

			p, blockquote {
				font-size: inherit;
			}
		}
	}

	pre {
		max-width: $content_width;
		margin: 2em auto;
	}

	.individual-bubble {
		position: absolute;
		border-radius: 100%;
		bottom: 10px;
		background-color: #fff;
		z-index: 1;
		overflow: hidden;
		pointer-events: none;
	}


}