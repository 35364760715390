#sprk-theme {

	// Logo

	.sprk-logo-wrapper {

		.sprk-logo-wrapper-inner {
			
			h1 {
				font-family: 'Playfair Display';
				font-size: 2.8em;
				line-height: 1.2;
				text-align: center;
				font-weight: bold;
				letter-spacing: -2px;
				color: color(primary);

				span {
					display: block;
					// opacity: 0.5;
					font-family: 'Raleway';
					font-size: 0.23em;
					letter-spacing: 2px;
					text-transform: uppercase;
				}
			}
		}

		
	}

	.main-nav__logo {
		.circle {
			transform: scale(0.6);
		}
	}

	.sprk-logo {
		position: relative;
		max-width: 400px;
		margin: auto;

		img {
			width: 100%;
		}

		.circle {
			position: absolute;
			top: 18px;
			left: 181px;

			transform: scale(0.83);

			@include susy-breakpoint($bp-md, $susy) {
				top: 26px;
			left: 222px;
				transform: scale(1);
			}

		}
	}

	.circle {
		position: relative;
		width: 60px;
		height: 60px;
		margin: 0 auto;
		border-radius: 100%;
		// border: 1px solid red;

		&:before {
			content: '';
			position: absolute;
			top: 50%;
			left: 50%;
			margin: -25px 0 0 -25px;
			display: block;
			height: 50px;
			width: 50px;
			border-top: 11px solid color(primarydark);
			border-left: 11px solid color(tertiary);
			border-right: 11px solid color(secondary);
			border-bottom: 11px solid color(primary);
			border-radius: 2000%;
			// transform: translate(-50%, -50%);
			-webkit-animation: rotation 0.3s linear infinite;
			animation: rotation 0.3s linear infinite;
			// opacity: 0;

			// border-top: 14px solid color(white);
			// border-left: 14px solid color(white);
			// border-right: 14px solid color(white);
			// border-bottom: 14px solid color(white);
		}

		&:after {
			content: '';
		    position: absolute;
		    top: 4px;
		    left: 23px;
		    display: block;
		    height: 14px;
		    width: 14px;
		    border-top: 2px solid color(white);
			border-left: 2px solid color(white);
		    transform: rotate(-45deg);
		    border-radius: 2000%;
		    box-shadow: -6px -3px 6px 0px rgba(0, 0, 0, 0.35);
		}

		.circle-01,
		.circle-02,
		.circle-03,
		.circle-04,
		.circle-05,
		.circle-06,
		.circle-07 {
			content: '';
			display: block;
			position: absolute;
			z-index: 100;
			width: 8px;
			height: 8px;
			background: color(white);
			border-radius: 200%;
		}

		.circle-01 {
			top: -2px;
			right: 6px;
			background: color(primary);
			// background: blue;
		}

		.circle-02 {
			top: 7px;
			right: 0;
			background: color(secondary);
			transform: scale(0.7);
		}

		.circle-03 {
			top: 8px;
			right: 8px;
			transform: scale(0.7);
		}

		.circle-04 {
			bottom: 16px;
			right: 7px;
			transform: scale(0.5);
		}

		.circle-05 {
			bottom: 7px;
			right: 7px;
			transform: scale(0.8);
		}

		.circle-06 {
			bottom: 27px;
			left: 18px;
			background: color(primary);
			transform: scale(0.5);
		}

		.circle-07 {
			bottom: 18px;
			left: 14px;
			background: color(secondary);
			transform: scale(0.9);
		}

		@-webkit-keyframes rotation {
			0% {
				transform: rotate(0deg);
			}
			100% {
				transform: rotate(-359deg);
			}
		}

		@keyframes rotation {
			0% {
				transform: rotate(0deg);
			}
			100% {
				transform: rotate(-359deg);
			}
		}
	}


}