
#sprk-theme {

	// WORKSHEET

	.sprk-worksheet-wrapper {

		&.default {
			padding: 20px;
			border-radius: 5px;
			@include gradient(right, color(tertiary), color(tertiarydark));

			.btn {
				background: color(tertiarydark);
			}
		}


		&.featured {
			padding: 20px;
			border-radius: 5px;
			@include gradient(right, color(secondary), color(secondarydark));

			.btn {
				background: color(secondarydark);
			}
		}

		&.featured--alt {
			padding: 20px;
			border-radius: 5px;
			@include gradient(right, color(primary), color(primarydark));

			.btn {
				background: color(primarydark);
			}
		}


		.sprk-worksheet-wrapper-inner {
			display: flex;
			flex-wrap: wrap;
			justify-content: left;

			@include susy-breakpoint($bp-md, $susy) {
					
			}

			// HOVER

			&:hover {
				.sprk-intro {
					.fas,
					.far,
					.fab {
						&:before {
							display: inline-block;
							animation: wobble 0.3s;
						}

						&:after {
							transform: scale(1.3);
						}
					}
				}
			}

			.sprk-intro {
				min-width: 100%;
				padding: 20px;
				margin-bottom: gutter();
				// background: color(secondaryghost);
				background: rgba(0,0,0,0.05);
				border-radius: 5px;
				text-align: center;

				&:after,
				&:before {
					content: '';
					position: absolute;
					top: -7px;
					left: 85%;
					z-index: 1;
					width: 15px;
					height: 15px;
					background: color(white);
					border-radius: 200%;
					transform: scale(0.5);
				}

				&:after {
					top: 10px;
					left: 80%;
					transform: scale(1);
					opacity: 0.35;
				}

				h3 {
					margin: 0 auto 1em auto;
					font-size: 1.2em;
					text-align: left;
					color: color(white);
					@include textShadow(1);
					border-radius: 5px 5px 0 0;

					span {
						display: block;
						font-size: 0.65em;
						font-weight: normal;
						line-height: 1.5em;
						text-transform: uppercase;
						text-shadow: none;
						opacity: 0.75;
					}

					i {
						color: color(white);
					}
				}

				p {
					font-size: 14px;
				    line-height: 1.5;
				    text-align: left;
				    margin: 10px auto;
					color: color(white);
					
					&.price {
						font-size: 2em;
						line-height: 1em;
						font-weight: bold;

						span {
							display: block;
							font-size: 0.4em;
							line-height: 1.5em;
							font-weight: 100;
							text-transform: uppercase;
							opacity: 0.75;
						}
					}
				}

				strong {
					color: inherit;
				}

				@include susy-breakpoint($bp-md, $susy) {
					h3, p {
						text-align: center;
					}
				}

				.fas,
				.far,
				.fab {
					position: relative;
					width: 60px;
					height: 60px;
					margin: 10px auto;
					font-size: 22px;
					line-height: 60px;
					color: color(white);
					border: 1px solid color(white);
					border-radius: 200%;

					&:after {
						content: '';
						position: absolute;
						top: -1px;
						left: -1px;
						z-index: 1;
						width: 60px;
						height: 60px;
						border: 1px solid color(white);
						border-radius: 200%;
						transform: scale(1);
						transition: 0.3s ease;
						opacity: 0.35;
					}
				}
			}
			
			.sprk-worksheet {
				flex: auto;
				flex-basis: 0;
				
				min-width: 100%;
				margin-right: gutter();
				margin-bottom: gutter();
				padding: 20px;
				background: color(tertiaryghost);
				background: rgba(255,255,255,0.03);
				font-size: 0.8em;
				border-radius: 5px;

				@include susy-breakpoint($bp-md, $susy) {
					min-width: span(4 of 12);

					&:nth-child(4n),
					&:last-child {
						margin-right: 0;
						
					}
				}


				h3 {
					margin: 0 0 1em 0;
					text-align: left;
					color: color(white);
				}

				p {
					max-width: 100%;
					font-size: 14px;
				    line-height: 1.5;
				}
				
				.fas,
				.fab,
				.far {
					color: color(tertiary);
					color: color(white);
				}

				.fa-times-circle {
					color: color(altdarker);
					color: rgba(0,0,0,0.25);
				}

				ul {
					position: relative;
					margin: 0 auto;
					padding: 0;

					li {
						position: relative;
						margin: 5px 0;
						padding: 5px 5px 5px 25px;
						line-height: 1.5em;
						text-align: left;
						list-style-type: none;
						border-bottom: 1px solid color(primarylight);
						color: color(white);
						border-bottom: 1px solid rgba(255,255,255,0.2);

						&:last-child {
							border-bottom: none;
						}

						strong {
							float: right;
						}

						.fas,
						.fab,
						.far {
							content: '';
							@include center(false, true);
							left: 0;		
						}
					}
				}
			}
	  

			
		}
	}
	

}