
#sprk-theme {

	// SLIDER

	.flexslider {
		position: relative;

		blockquote {
			background: url("/assets/img/banner.png") center no-repeat;
			background-size: cover;
		}

		ul.flex-direction-nav {

			li {
				@include center(false, true);

				&.flex-nav-prev {
					left: 0;

					a {
						&:before {
							content: '\f053';
							margin-right: -2px;
						}
					}
				}
				&.flex-nav-next {
					right: 0;

					a {
						&:before {
							content: '\f054';
							margin-left: 2px;
						}
					}
				}

				a {
					position: relative;
					display: block;
					width: 40px;
					height: 40px;
					color: color(white);
					background: color(white);
					border-radius: 100%;
					@include boxShadow(16);
					overflow: hidden;
					transition: 0.3s ease;

					&:hover {
						color: color(tertiary);
						background: color(tertiary);
						transform: scale(0.9);

						&:before {
							color: color(white);
						}
					}

					&:before {
						display: block;
						content: '';
						@include center(true,true);
						width: 40px;
						height: 40px;
						text-align: center;
						line-height: 40px;
						color: color(tertiary);
						font-family: 'Font Awesome\ 5 Free', 'FontAwesome';
						transition: 0.3s ease;
					}
				}
			}
		}

		ol.flex-control-nav {
			li {
				display: inline-block;
				margin: 5px;

				a {
					display: block;
					width: 8px;
					height:  8px;
					text-indent: -1000%;
					background: color(secondary);
					overflow: hidden;
					border-radius: 100%;
					background: color(secondary);

					&.flex-active {
						background: color(primary);
					}
				}
			}
		}
	}
	

}