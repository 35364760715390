/// All colors in standard variable format.
///
/// @group color

$layout_width:  1400px;
$content_width:  780px;
$sidebar_width:  300px;


$colors: (
    // section--darks
	black: #000000,
	nero: #151515,
	charcoal: #232323,
	ashes: #333333,

	// Mids
	dusty: #999999,
	boulder: #7a7a7a,
	silver: #c0c0c0,
	alto: #eeeeee,

	// Lights
	white: #FFFFFF,
	whitesmoke: #f4f4f4,
	ghost: #f9f9f9,

	// Dust
	celeste: #D1D2CA,
	westar: #DCD9D2,
	feta: #E8EBE0,
	desertstorm: #F4F2EE,

	//SparkOlux
	
	primaryghost: #fff8fc,
	primarylight: #ffebf7,
	primary: #db7eb6,
	primarydark: #da57a0,
	primarydarker: #4a0c2f,

	secondaryghost: #f4fbff,
	secondary: #65b6e6,
	secondarydark: #5298c2,
	secondarydarker: #113a54,

	tertiaryghost: #fdf8ff,
	tertiary: #b594c2,
	tertiarydark: #a885b5,
	tertiarydarker: #3c0e4d,

	altghost: #ffe59a,
	alt: #ffd634,
	altdark: #ffaf2c,
	altdarker: #ad3929,

);



// Box Shadows


.box-shadow-1dp {
	@include boxShadow(1);
}

.box-shadow-4dp {
	@include boxShadow(4);
}

.box-shadow-6dp {
	@include boxShadow(6);
}
.box-shadow-8dp {
	@include boxShadow(8);
}

.box-shadow-12dp {
	@include boxShadow(12);
}

.box-shadow-16dp {
	@include boxShadow(16);
}
.box-shadow-24dp {
	@include boxShadow(24);
}

// Text Shadows


.text-shadow-1dp {
	@include boxShadow(1);
}

.text-shadow-4dp {
	@include boxShadow(4);
}

.text-shadow-6dp {
	@include boxShadow(6);
}
.text-shadow-8dp {
	@include boxShadow(8);
}

.text-shadow-12dp {
	@include boxShadow(12);
}

.text-shadow-16dp {
	@include boxShadow(16);
}
.text-shadow-24dp {
	@include boxShadow(24);
}

