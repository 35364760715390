
#sprk-theme {

	// PACKAGE

	.sprk-package-wrapper {

		.sprk-package-wrapper-inner {
			display: flex;
		    flex-wrap: wrap;
		    justify-content: center;
			width: 100%;
			margin: 0 auto;
			padding: 0 20px;
			text-align: center;
		  
			.sprk-package {
				position: relative;
				width: 100%;
				display: inline-block;
				margin: 10px 0;
				padding-bottom: 70px; // same as footer pricce
				border-radius: 5px;
				background: color(white);
				@include boxShadow(8);
				transition: 0.2s ease;
				vertical-align: top;
				@include gradient(right, color(tertiary), color(tertiarydark));

				@include susy-breakpoint($bp-md, $susy) {
					flex: auto;
					flex-basis: 0;
					margin-right: gutter();

					&:hover {
					// transform: scale(1.01);
					}

					&:nth-child(3n),
					&:last-child {
						// @include span(4 of 12 last);
					}
				}

				// HOVER

				&:hover {
					.fas,
					.far,
					.fab {
						&:before {
							display: inline-block;
							animation: wobble 0.3s;
						}

						&:after {
							transform: scale(1.3);
						}
					}
				}

				.btn {
					background: color(tertiarydark);
				}

				&.featured {
					@include gradient(right, color(secondary), color(secondarydark));

					.btn {
						background: color(secondarydark);
					}
				}

				&.featured--alt {
					@include gradient(right, color(primary), color(primary));

					.btn {
						background: color(primarydark);
					}
				}
		    
				@include susy-breakpoint($bp-lg, $susy) {
					// width: 22%;
				}

			    
				&.free {

					.sprk-package--cta {
						color: #ccc;
					}
				}
			    
				img {
					display: block;
					width: 100%;
					height: auto;
				}
			    
				h3 {
					position: relative;
					max-width: 100%;
					font-size: 1.2em;
					padding: 20px;
					margin: 0;
					text-align: center;
					color: color(white);
					background: rgba(0,0,0,0.05);
					@include textShadow(1);
					border-radius: 5px 5px 0 0;

					&:after,
					&:before {
						content: '';
						position: absolute;
						top: -7px;
						left: 85%;
						z-index: 1;
						width: 15px;
						height: 15px;
						background: color(white);
						border-radius: 200%;
						transform: scale(0.5);
					}

					&:after {
						top: 10px;
						left: 80%;
						transform: scale(1);
						opacity: 0.35;
					}

					span {
						display: block;
						font-size: 0.65em;
						font-weight: normal;
						line-height: 1.5em;
						opacity: 0.75;
						text-transform: uppercase;
						text-shadow: none;
					}

					i {
						color: color(white);
					}

				}
			    
				.sprk-package--content {
					position: relative;
					padding: 20px;

					@include susy-breakpoint($bp-md, $susy) {
						// min-height: 260px;
					}

					&:after,
					&:before {
						content: '';
						position: absolute;
						top: 110px;
						left: 95%;
						z-index: 1;
						width: 15px;
						height: 15px;
						background: color(primary);
						background: color(white);
						border-radius: 200%;
						transform: scale(0.5);
					}

					&:after {
						top: 140px;
						left: 97%;
						background: color(secondary);
						background: color(white);
						transform: scale(1);
						opacity: 0.5;
					}

					ul {
						padding: 10px 0;
						margin: 0;
						text-align: left;

						li {
							font-size: 0.8em;
							list-style: none;
							padding: 10px;
							color: color(white);
							border-bottom: 1px solid rgba(255,255,255,0.2);

							&:last-child {
								border-bottom: none;
							}

							strong {
								float: right;
							}
						}
					}

					p {
						font-size: 0.9em;
						line-height: 1.5em;
						text-align: left;
						color: color(white);

						&.price {
							font-size: 2em;
							line-height: 1em;
							font-weight: bold;
							text-align: center;
							margin: 10px 0;

							span {
								display: block;
								font-size: 0.4em;
								line-height: 1.5em;
								font-weight: 100;
								text-transform: uppercase;
							}
						}
					}


				}

				.fas,
				.far,
				.fab {
					position: relative;
					width: 60px;
					height: 60px;
					margin: 10px auto;
					font-size: 22px;
					line-height: 60px;
					color: color(white);
					border: 1px solid color(white);
					border-radius: 200%;

					&:after {
						content: '';
						position: absolute;
						top: -1px;
						left: -1px;
						z-index: 1;
						width: 60px;
						height: 60px;
						border: 1px solid color(white);
						border-radius: 200%;
						transform: scale(1);
						transition: 0.3s ease;
						opacity: 0.35;
					}
				}

				.btn, .button {
					width: 100%;
					margin-bottom: 0;
					font-size: 14px;
				}

				.sprk-package--cta {
					position: absolute;
					bottom: 0;
					left: 0;
					height: 70px;
					width: 100%;
					padding: 5px 20px;
					text-align: center;
					font-size: 1.3em;
					font-weight: bold;
					line-height: 50px;
					font-family: 'Roboto Condensed', sans-serif;
					color: color(charcoal);
					background: rgba(255,255,255,0.08);
					border-radius: 0 0 5px 5px;

					&:after,
					&:before {
						content: '';
						position: absolute;
						top: -20px;
						left: 15px;
						z-index: 1;
						width: 15px;
						height: 15px;
						background: color(secondary);
						background: color(white);
						border-radius: 200%;
						transform: scale(0.5);
					}

					&:after {
						top: -5px;
						left: -5px;
						background: color(primary);
						background: color(white);
						transform: scale(1);
						opacity: 0.35;
					}

					span {
						font-weight: normal;
						font-size: .7em;
					}
				}
			   
			    
			}
		}
	}	

	// BOX

	.sprk-box-wrapper {

		.sprk-box-wrapper-inner {
			display: flex;
		    flex-wrap: wrap;
		    justify-content: center;
			width: 100%;
			margin: 0 auto;
			padding: 0 20px;
			text-align: center;
		  
			.sprk-box {
				position: relative;
				width: 100%;
				display: inline-block;
				min-width: span(6 of 12);
				max-width: span(6 of 12);
				margin: 10px 0;
				margin-right: gutter();
				padding: 20px 10px; 
				border-radius: 5px;
				background: color(white);
				@include boxShadow(8);
				transition: 0.2s ease;
				vertical-align: top;
				border-radius: 10px;

				&:nth-child(2n),
				&:last-child {
					margin-right: 0;
				}

				@include susy-breakpoint($bp-md, $susy) {
					flex: auto;
					flex-basis: 0;
					min-width: span(2 of 12);
					max-width: span(2 of 12);
					margin-right: gutter();

					&:hover {
						
					}

					&:nth-child(2n) {
						margin-right: gutter();
					}

					&:nth-child(5n),
					&:last-child {
						margin-right: gutter();
					}
				}

				// HOVER

				&:hover {
					.fas,
					.far,
					.fab {
						&:before {
							display: inline-block;
							animation: wobble 0.3s;
						}

						&:after {
							transform: scale(1.3);
						}
					}
				}


				h5 {
					margin: 0.5em auto;
				}

				p {
					margin: 1em auto 0;
					font-size: 14px;
				}

				.fas,
				.far,
				.fab {
					position: relative;
					width: 60px;
					height: 60px;
					margin: 10px auto;
					font-size: 22px;
					line-height: 60px;
					color: color(primary);
					border: 1px solid color(tertiary);
					border-radius: 200%;

					&:after {
						content: '';
						position: absolute;
						top: -1px;
						left: -1px;
						z-index: 1;
						width: 60px;
						height: 60px;
						border: 1px solid color(tertiary);
						border-radius: 200%;
						transform: scale(1);
						transition: 0.3s ease;
						opacity: 0.35;
					}
				}
			   
			    
			}
		}
	}
	

}