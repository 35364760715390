
#sprk-theme {

	// PACKAGE

	.sprk-modal-wrapper {
		display: none;
		position: absolute;
		top: 0;
		left: 0;
		z-index: 999;
		width: 100%;
		height: 100%;
		padding: 40px;
		background: rgba(74,12,47,0.75);
		background: rgba(255,255,255,0.8);

		.sprk-modal--trigger {
			position: fixed;
			top: 10px;
			right: 10px;
			z-index: 100;
			width: 40px;
			height: 40px;
			line-height: 40px;
			text-align: center;
			font-size: 18px;
			color: color(tertiary);
			border-radius: 100%;
			background: color(white);
			box-shadow: 10px 0px 20px rgba(0,0,0,0.35);
			border: 1px solid color(white);
			transition: 0.3s ease;

			&:hover {
				color: color(white);
				background: color(tertiary);
				transform: scale(0.95);
			}

			@include susy-breakpoint($bp-md, $susy) {
				top: 25px;
				right: 9%;
			}
		}
		  
		.sprk-modal {
			position: fixed;
			top: 20px;
			bottom: 20px;
			right: 20px;
			left: 20px;
			max-width: $layout_width;
			max-height: 100vh;
			padding-bottom: 70px;
			background: color(white);
			@include gradient(right, color(tertiary), color(tertiarydark));
			border-radius: 10px;
			@include boxShadow(16);
			box-shadow: 10px 0px 20px rgba(0,0,0,0.15);

			@include susy-breakpoint($bp-md, $susy) {
				@include center(true,false);
				position: fixed;
				top: 40px;
				bottom: 40px;
				right: auto;
				width: 80%;
			}

			&:after,
			&:before {
				content: '';
				position: absolute;
				top: 32%;
				left: 95%;
				z-index: 1;
				width: 15px;
				height: 15px;
				background: color(primary);
				background: color(white);
				border-radius: 200%;
				transform: scale(0.5);
			}

			&:after {
				top: 40%;
				left: 97%;
				background: color(secondary);
				background: color(white);
				transform: scale(1);
				opacity: 0.5;
			}

			.inner {
				position: relative;
				height: 100%;
				padding: 20px;
				overflow-y: scroll;
			}

			.btn {
				background: color(tertiarydark);
			}

			&.featured {
				@include gradient(right, color(secondary), color(secondarydark));

				.btn {
					background: color(secondarydark);
				}
			}

			&.featured--alt {
				@include gradient(right, color(primary), color(primary));

				.btn {
					background: color(primarydark);
				}
			}

			.modal-footer {
				position: absolute;
				bottom: 0;
				left: 0;
				height: 70px;
				width: 100%;
				padding: 5px 20px;
				text-align: center;
				font-size: 1.3em;
				font-weight: bold;
				line-height: 50px;
				font-family: 'Roboto Condensed', sans-serif;
				color: color(charcoal);
				background: rgba(255,255,255,0.08);
				// @include gradient(right, color(tertiary), color(tertiarydark));
				border-radius: 0 0 5px 5px;
				box-shadow: -10px 0 20px rgba(0,0,0,0.15);

				@include susy-breakpoint($bp-md, $susy) {
					text-align: right;
				}

				&:after,
				&:before {
					content: '';
					position: absolute;
					top: -20px;
					left: 15px;
					z-index: 1;
					width: 15px;
					height: 15px;
					background: color(secondary);
					background: color(white);
					border-radius: 200%;
					transform: scale(0.5);
				}

				&:after {
					top: -5px;
					left: -5px;
					background: color(primary);
					background: color(white);
					transform: scale(1);
					opacity: 0.35;
				}

				span {
					font-weight: normal;
					font-size: .7em;
				}

				.btn {
					width: 100%;
					color: color(secondarydarker);
					background: color(white);

					@include susy-breakpoint($bp-md, $susy) {
						width: auto;
					}
				}
			}
		    
		}

	}
	

}