// Mixins



@mixin objectFit {
	object-fit: cover;
	display: block;
	width: 100%;
	height: 100%;
	pointer-events: none;
}

@mixin center($horizontal: true, $vertical: true) {
	position: absolute;
	// width: 100%;

	@if ($horizontal and $vertical) {
		
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);

	} @else if ($horizontal) {

		left: 50%;
		transform: translate(-50%, 0);

	} @else if ($vertical) {

		top: 50%;
		transform: translate(0, -50%);
		
	}
}

// Box Shadows

@mixin boxShadow($dp) {

	@if $dp == 1 {

		box-shadow: 0px 2px 1px -1px rgba(181, 148, 194, 0.25), 
					0px 1px 1px 0px rgba(181, 148, 194, 0.18), 
					0px 1px 3px 0px rgba(181, 148, 194, 0.12);
	}

	@else if $dp == 4 {

		box-shadow: 0px 2px 4px -1px rgba(181, 148, 194, 0.25), 
					0px 4px 5px 0px rgba(181, 148, 194, 0.18), 
					0px 1px 10px 0px rgba(181, 148, 194, 0.12);
	}

	@else if $dp == 6 {

		box-shadow: 0px 3px 5px -1px rgba(181, 148, 194, 0.25), 
					0px 6px 10px 0px rgba(181, 148, 194, 0.18), 
					0px 1px 18px 0px rgba(181, 148, 194, 0.12);
	}

	@else if $dp == 8 {

		box-shadow: 0px 5px 5px -3px rgba(181, 148, 194, 0.25), 
					0px 8px 10px 1px rgba(181, 148, 194, 0.18), 
					0px 3px 14px 2px rgba(181, 148, 194, 0.12);
	}

	@else if $dp == 12 {

		box-shadow: 0px 7px 8px -4px rgba(181, 148, 194, 0.25), 
					0px 12px 17px 2px rgba(181, 148, 194, 0.18), 
					0px 5px 22px 4px rgba(181, 148, 194, 0.12);
	}

	@else if $dp == 16 {

		box-shadow: 0px 8px 10px -5px rgba(181, 148, 194, 0.25), 
					0px 16px 24px 2px rgba(181, 148, 194, 0.18), 
					0px 6px 30px 5px rgba(181, 148, 194, 0.12);
	}

	@else if $dp == 24 {

		box-shadow: 0px 11px 25px -7px rgba(181, 148, 194, 0.25), 
					0px 24px 38px 3px rgba(181, 148, 194, 0.15), 
					0px 9px 46px 8px rgba(181, 148, 194, 0.12);
	}

}

// Text Shadows

@mixin textShadow($dp) {

	@if $dp == 1 {

		text-shadow: 0px 2px 1px rgba(181, 148, 194, 0.25), 
					0px 1px 1px rgba(181, 148, 194, 0.15), 
					0px 1px 3px rgba(181, 148, 194, 0.12);
	}

	@else if $dp == 4 {

		text-shadow: 0px 2px 4px rgba(181, 148, 194, 0.25), 
					0px 4px 5px rgba(181, 148, 194, 0.15), 
					0px 1px 10px rgba(181, 148, 194, 0.12);
	}

	@else if $dp == 6 {

		text-shadow: 0px 3px 5px rgba(181, 148, 194, 0.25), 
					0px 6px 10px rgba(181, 148, 194, 0.15), 
					0px 1px 18px rgba(181, 148, 194, 0.12);
	}

	@else if $dp == 8 {

		text-shadow: 0px 5px 5px rgba(181, 148, 194, 0.25), 
					0px 8px 10px rgba(181, 148, 194, 0.15), 
					0px 3px 14px rgba(181, 148, 194, 0.12);
	}

	@else if $dp == 12 {

		text-shadow: 0px 7px 8px rgba(181, 148, 194, 0.25), 
					0px 12px 17px rgba(181, 148, 194, 0.15), 
					0px 5px 22px rgba(181, 148, 194, 0.12);
	}

	@else if $dp == 16 {

		text-shadow: 0px 8px 10px rgba(181, 148, 194, 0.25), 
					0px 16px 24px rgba(181, 148, 194, 0.15), 
					0px 6px 30px rgba(181, 148, 194, 0.12);
	}

	@else if $dp == 24 {

		text-shadow: 0px 11px 15px rgba(181, 148, 194, 0.25), 
					0px 24px 38px rgba(181, 148, 194, 0.15), 
					0px 9px 46px rgba(181, 148, 194, 0.12);
	}

}


// Gradient

@mixin gradient($direction, $from, $to) {
  // background: $to;
  // background: -ms-linear-gradient($direction, $from, $to);
  background:  linear-gradient(to $direction, $from, $to);
  background: -moz-linear-gradient($direction, $from, $to);
  background: -o-linear-gradient($direction, $from, $to);
  background: -webkit-gradient(linear, $direction, from($from), to($to));
  background: -webkit-linear-gradient($direction, $from, $to);
}


