#sprk-theme {

	.sprk-header-wrapper {
		display: none;
		background: black;

		&.hide {
			opacity: 0;
		}

		.sprk-header-wrapper-inner {
			// max-width: $layout-width;
			height: 36px;
			margin: auto;
			padding: 0 20px;
			text-align: left;

			ul.header-nav {
				display: inline-block;
				margin: 0px auto;
				padding: 0px;
				line-height: 0;
				@include container();

				li {
					display: inline-block;
					margin: 0px 10px;

					a {
						display: inline-block;
						line-height: 36px;
						color: color(white);
						text-transform: uppercase;
						font-size: 12px;
					}

					i {
						color: color(white);
					}

					&.first {
						margin-left: 20px;
					}
					&.last {
						margin-right: 20px;
					}
				}

				&.left {
					@include span( 3 of 12);
				}
				&.right {
					@include span( 3 of 12 last);
					text-align: right;
				}
			}
		}

		&.section--dark {
			background: rgba(0,0,0,0.3);
			// background: color(charcoal);
		}

		&.light {
			background: rgba(0,0,0,0.05);
			background: color(white);

			ul.header_nav {
				li {
					a {
						color: color(charcoal);
					}
				}
			}
		}
	}
}